<template>
  <div class="app-card">
    <a-form-model
      ref="ruleForm"
      :model="form"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <!-- <a-form-model-item label="门店图片">
        <a-row>
          <a-col style="display: contents;" :span="8" v-if="['create', 'edit'].includes(formMode)">
            <a-upload-dragger
              :multiple="true"
              list-type="picture-card"
              :file-list="files"
              :showUploadList="true"
              :remove="handleRemoveImage"
              @preview="handlePreview"
              :beforeUpload="beforeUploadImage"
              @change="changeUploadImage"
            >
              <p class="ant-upload-drag-icon">
                <a-icon type="inbox" />
              </p>
              <p class="ant-upload-text">
                图片上传
              </p>
              <p class="ant-upload-hint">
                仅支持上传JPG或PNG图片
              </p>
            </a-upload-dragger>
          </a-col>
          <a-col :span="16" v-if="beforeFiles && beforeFiles.length > 0">
            <a-upload
              list-type="picture-card"
              :file-list="beforeFiles"
              @preview="handlePreview"
              :remove="handleRemoveBeforeImage"
              :beforeUpload="() => false"
            >
            </a-upload>
          </a-col>
        </a-row>
      </a-form-model-item> -->
      <a-form-model-item label="商家名称" prop="name">
        <a-input v-model="form.name" />
      </a-form-model-item>
      <a-form-model-item label="简介" prop="shopProfile">
        <a-textarea v-model="form.shopProfile" :auto-size="{ minRows: 3, maxRows: 5 }" />
      </a-form-model-item>
      <a-form-model-item label="积分转换比例(%)" prop="scoreRate">
        <a-input-number v-model="form.scoreRate" :min="0" :max="100" />
      </a-form-model-item>
      <a-form-model-item label="佣金比例(%)" prop="commissionRate">
        <a-input-number v-model="form.commissionRate" :min="0" :max="100" />
      </a-form-model-item>
      <a-form-model-item label="商品">
        <a-row :gutter="[8,8]">
          <a-col :span="12" v-for="item in form.commodity" :key="item.key">
            <a-card style="width: 100%">
              <a-icon
                slot="extra"
                v-if="form.commodity.length > 1"
                class="dynamic-delete-button"
                type="minus-circle-o"
                :disabled="form.commodity.length === 1"
                @click="removeCommodity(item)"
              />
              <a-input slot="title" style="width:90%" addon-before="名称" v-model="item.name" />
              <a-textarea
                v-model="item.profile"
                placeholder="商品简介"
                :auto-size="{ minRows: 3, maxRows: 5 }"
              />
              <div slot="actions">
                <a-input style="width:90%" addon-before="商品单价或价格说明" v-model="item.price" />
              </div>
            </a-card>
          </a-col>
        </a-row>
      </a-form-model-item>
      <a-form-model-item v-bind="formItemLayoutWithOutLabel">
        <a-button type="dashed" style="width: 60%" @click="addCommodity">
          <a-icon type="plus" /> 添加供应商商品
        </a-button>
      </a-form-model-item>
      <a-form-model-item label="商家介绍" style="padding-bottom:130px">
        <quill-editor
          style="height:440px;"
          v-model="form.content"
          :options="{placeholder: '请填写内容', ...editerOptions}"
        />
      </a-form-model-item>
      <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
        <a-button type="primary" @click="onSubmit">
          提交
        </a-button>
      </a-form-model-item>
    </a-form-model>
    <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancelPreview">
      <div v-viewer>
        <img alt="example" style="width: 100%" :src="previewImage" />
      </div>
    </a-modal>
    <a-modal
      :width="200"
      :visible="modalLoading"
      :closable="false"
      :maskClosable="false"
      :footer="null"
    >
      <a-row>
        <a-col style="text-align: center;">
          <a-icon type="loading" :style="{ fontSize: '36px', color: '#1890ff' }" />
        </a-col>
        <a-col style="text-align: center;color:#1890ff;padding-top:10px;">
          {{modalLoadingMessage}}
        </a-col>
      </a-row>
      
    </a-modal>
  </div>
</template>

<script>
import { Supplier } from "@/api";

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

export default {
  name: 'SupplierAddAndEdit',
  mounted () {
    if (this.$route.params && this.$route.params.uuid) {
      this.formMode = 'edit'
      this.getDetail(this.$route.params.uuid)
    }
  },
  data () {
    return {
      modalLoading: false,
      modalLoadingMessage: '正在处理数据...',
      labelCol: { span: 4 },
      wrapperCol: { span: 16 },
      formItemLayout: {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 4 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 20 },
        },
      },
      formItemLayoutWithOutLabel: {
        wrapperCol: {
          xs: { span: 24, offset: 0 },
          sm: { span: 20, offset: 4 },
        },
      },
      formMode: 'create',
      beforeData: null,
      form: {
        name: '',
        shopProfile: '',
        scoreRate: 0,
        commissionRate: 0,
        content: '',
        commodity: []
      },
      rules: {
        name: [
          { required: true, message: '请填写', trigger: 'blur' },
        ],
        shopProfile: [
          { required: true, message: '请填写', trigger: 'blur' },
        ],
        scoreRate: [
          { required: true, message: '请填选择', trigger: 'change' },
        ],
        commissionRate: [
          { required: true, message: '请填选择', trigger: 'change' },
        ],
        content: [
          { required: true, message: '请填写', trigger: 'blur' },
        ],
      },
      insuranceBaseList: [],
      files: [],
      beforeFiles: [],
      previewVisible: false,
      previewImage: '',
      map: {
        center: {lng: 0, lat: 0},
        zoom: 3
      },
      BMap: null,
      belongList: [],
      fetchingBelong: false,
      editerOptions: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"], //加粗，斜体，下划线，删除线
            ["blockquote", "code-block"], //引用，代码块
            [{ header: 1 }, { header: 2 }], // 标题，键值对的形式；1、2表示字体大小
            [{ list: "ordered" }, { list: "bullet" }], //列表
            [{ script: "sub" }, { script: "super" }], // 上下标
            [{ indent: "-1" }, { indent: "+1" }], // 缩进
            [{ direction: "rtl" }], // 文本方向
            [{ size: ["small", false, "large", "huge"] }], // 字体大小
            [{ header: [1, 2, 3, 4, 5, 6, false] }], //几级标题
            [{ color: [] }, { background: [] }], // 字体颜色，字体背景颜色
            [{ font: [] }], //字体
            [{ align: [] }], //对齐方式
            ["link"],
            ["clean"], //清除字体样式
            ["image"], //上传图片、上传视频
          ],
        },
        theme: "snow",
      },
    }
  },
  methods: {
    mapReaderhandler({BMap, map}) {
      const _this = this
      this.BMap = BMap;
      this.map = map;
      map.addEventListener('click', function (e) {
        _this.form.addressPosition = e.point
      });
    },
    searchAddressPosition(value) {
      if (this.BMap === null) {
        this.$message.error('地图查询未初始化')
        return
      }
      const mapGeocoder = new this.BMap.Geocoder();
      const _this = this
      mapGeocoder.getPoint(value, function (point) {
        if (point) {
          _this.form.addressPosition = { lng: point.lng, lat: point.lat }
        }
      })
    },
    addCommodity() {
      this.form.commodity.push({
        name: '',
        profile: '',
        price: ''
      });
    },
    removeCommodity(item) {
      let index = this.form.commodity.indexOf(item);
      if (index !== -1) {
        this.form.commodity.splice(index, 1);
      }
    },
    async getDetail (uuid) {
      const apiRes = await this.$axios.get(`${Supplier}/Detail?uuid=${uuid}`)
        .catch(e => {
          return {
            status: false,
            message: e.message.indexOf('401') > -1 ? '登录超时' : e.message
          }
        })
      if (!apiRes.status) {
        this.$message.error(apiRes.message);
        this.sending = false;
        return null;
      }
      this.beforeData = apiRes.data
      const copyData = JSON.parse(JSON.stringify(apiRes.data))
      const form = {
        name: copyData.name,
        shopProfile: copyData.shopProfile,
        scoreRate: copyData.scoreRate,
        commissionRate: copyData.commissionRate,
        content: copyData.content,
        commodity: copyData.commodity
      }
      this.$set(this, 'form', form)
    },
    // 图片上传处理
    handleRemoveImage(file) {
      const index = this.files.indexOf(file);
      const newFileList = this.files.slice();
      newFileList.splice(index, 1);
      this.files = newFileList;
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    handleCancelPreview() {
      this.previewVisible = false;
    },
    beforeUploadImage(file) {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png'
      if (!isJPG) {
        this.$message.error('只能上传JPG或PNG图片')
        return
      }
      return false
    },
    changeUploadImage({ fileList }) {
      this.files = fileList
    },
    handleRemoveBeforeImage() {
      this.$message.error('已上传文件无法删除，请通过重新上传文件覆盖原有上传文件')
      return false
    },
    async UploadOrderImages(uuid) {
      if (this.files.length < 1) return
      const formData = new FormData();
      for (const it of this.files) {
        formData.append('imageFiles', it.originFileObj, it.originFileObj.name)
      }
      const apiRes = await this.$axios.post(`${Supplier}/UploadImages?uuid=${uuid}`, formData)
        .catch(e => {
          return {
            status: false,
            message: e.message.indexOf('401') > -1 ? '登录超时' : e.message
          }
        })
      if (!apiRes.status) {
        throw new Error(apiRes.message)
      }
      return
    },
    onSubmit() {
      this.$refs.ruleForm.validate(async valid => {
        if (!valid) {
          this.$message.error('请检查填写内容')
          return false
        }
        
        // 商家介绍
        if (!this.form.shopProfile) {
          this.$message.error('请填写供应商介绍')
          return false
        }
        if (this.form.commodity.length < 1) {
          this.$message.error('请添加至少一个商品')
          return false
        }
        const postData = JSON.parse(JSON.stringify(this.form))
        let url = ''
        if (this.formMode === 'create') {
          url = `${Supplier}/Create`
        } else {
          url = `${Supplier}/Edit?uuid=${this.beforeData.uuid}`
        }
        this.modalLoadingMessage = '正在处理数据'
        this.modalLoading = true
        const res = await this.$axios.post(url, postData)
          .catch(e => {
            return {
              status: false,
              message: e.message.indexOf('401') > -1 ? '登录超时' : e.message
            }
          })
        if (!res.status) {
          this.modalLoading = false
          this.$message.error(res.message)
          return
        }
        this.modalLoading = false
        // this.modalLoadingMessage = '正在处理图片'
        // await this.UploadOrderImages(res.data.uuid)
        //   .catch(e => {
        //     this.modalLoading = false
        //     this.$message.error(e.message)
        //   })
        // this.modalLoading = false
        this.$router.replace({ name: 'SupplierTableList' }).catch(err => err)
      })
    }
  }
}
</script>

<style>
.map {
  width: 100%;
  height: 350px;
}
.dynamic-delete-button {
  cursor: pointer;
  position: relative;
  padding-left: 0px;
  top: 4px;
  font-size: 24px;
  color: #999;
  transition: all 0.3s;
}
.dynamic-delete-button:hover {
  color: #777;
}
.dynamic-delete-button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}
</style>